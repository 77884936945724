.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
}

.modalOverlay.show {
  opacity: 1;
  visibility: visible;
}

.modalContent {
  position: absolute;
  /* background: #fff; */
  border-radius: 8px;
  max-width: 100%;
  max-height: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transform: scale(0.7);
  /* transform: scaleY(0.7); */
  transition: transform 0.5s ease-in-out;
}

.modalOverlay.show .modalContent {
  transform: scaleY(1);
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 30px;
  line-height: 1;
  cursor: pointer;
  color: #fff;
  transition: color 0.3s ease;
}

.closeButton:hover {
  color: #000;
}

@media only screen and (max-width: 767px) {
  .modalContent {
    width: 85%;
  }
}